import { Capacitor, CapacitorHttp } from '@capacitor/core'

class LCapacitorHttp {
  async post(url, headers, data) {
    let options = {
      url,
      headers,
      data
    }
    let response = await CapacitorHttp.post(options)
    return response
  }
  async get(url, headers, params) {
    const options = {
      url,
      headers,
      params
    }
    let response = await CapacitorHttp.get(options)
    return response
  }

  async put(url, headers, data) {
    let options = {
      url,
      headers,
      data
    }
    let response = await CapacitorHttp.put(options)
    return response
  }
}

class ElectronHttp {
  constructor() {
    let https = window.require('https')
    this.https = https
    let http = window.require('http')
    this.http = http
  }

  async post(url, headers, data) {
    return new Promise((resolve, _reject) => {
      const urlObject = new URL(url)
      const protocol = urlObject.protocol
      const caller = protocol == 'https:' ? this.https : this.http
      let req = caller.request(
        {
          hostname: urlObject.hostname,
          port: urlObject.port || 443,
          method: 'POST',
          path: urlObject.pathname + urlObject.search,
          rejectUnauthorized: false,
          headers
        },
        res => {
          let body = ''
          res.on('data', chunk => {
            body += chunk
          })
          res.on('end', () => {
            resolve({ data: body })
          })
        }
      )
      req.write(data)
      req.end()
    })
  }

  async get(url, headers, params) {
    return new Promise((resolve, _reject) => {
      const urlObject = new URL(url)
      const protocol = urlObject.protocol
      const caller = protocol == 'https:' ? this.https : this.http
      let req = caller.request(
        {
          hostname: urlObject.hostname,
          port: urlObject.port || 443,
          method: 'GET',
          path: urlObject.pathname + '?' + new URLSearchParams(params),
          rejectUnauthorized: false,
          headers
        },
        res => {
          let body = ''
          res.on('data', chunk => {
            body += chunk
          })
          res.on('end', () => {
            resolve({ data: JSON.parse(body) })
          })
        }
      )
      req.end()
    })
  }

  async put(url, headers, data) {
    return new Promise((resolve, _reject) => {
      const urlObject = new URL(url)
      const protocol = urlObject.protocol
      const caller = protocol == 'https:' ? this.https : this.http
      let req = caller.request(
        {
          hostname: urlObject.hostname,
          port: urlObject.port || 443,
          method: 'PUT',
          path: urlObject.pathname + urlObject.search,
          rejectUnauthorized: false,
          headers
        },
        res => {
          let body = ''
          res.on('data', chunk => {
            body += chunk
          })
          res.on('end', () => {
            resolve({ data: body })
          })
        }
      )
      req.write(data)
      req.end()
    })
  }
}

class HttpFactory {
  static getHttp() {
    let platform = Capacitor.getPlatform()
    if (platform == 'electron') {
      return new ElectronHttp()
    } else if (platform != 'web') {
      return new LCapacitorHttp()
    }
    return null
  }
}
export default HttpFactory
